import { Component, NgZone } from '@angular/core';
import { register } from 'swiper/element/bundle';
import { StatusBar, Style } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';
import { Stripe } from '@capacitor-community/stripe';
import { environment } from 'src/environments/environment';
import { App, URLOpenListenerEvent } from '@capacitor/app'
import { Router } from '@angular/router';

register();
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private router: Router, private zone: NgZone) {
    this.initializeApp();
    this.initSplash();
    this.initStatusBar();
    this.initStripe();
  }

  private initializeApp(): void {
    App.addListener('appUrlOpen', (event:URLOpenListenerEvent) => {
      this.zone.run(() => {
        const domain = 'app.masterbowl.mx';
        const pathArray = event.url.split(domain);
        const appPath = pathArray.pop();

        if(appPath) this.router.navigateByUrl(appPath);
      });
    });
  }

  private async initSplash(): Promise<void>{
    await SplashScreen.show({
      autoHide: true,
      showDuration: 3000,
    });
  }

  private async initStatusBar(): Promise<void> {
    StatusBar.setBackgroundColor({
      color: '#2a1674'
    });
  }

  private initStripe(): void {
    Stripe.initialize({
      publishableKey: environment.stripeKey,
    });
  }
}
